export const labelClasses = [
	'tomato',
	'gold',
	'teal',
	'dodgerblue',
	'darkmagenta',
	'violet',
];

export const typeOptions = [
	'Brunch',
	'Dinner',
	'Movies',
	'Game Night',
	'Party',
];
